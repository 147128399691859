import React from 'react';
import logo from './logo.png';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className="App">
        <header className="App-header">
            <img src={logo} className="logo" alt="logo"/>
            <p>
                Alina sta arrivando.
            </p>
            <p>
                <a href={"https://www.instagram.com/l_ampoma"} title={"Instagram"}>
                    <FontAwesomeIcon icon={faInstagram} />
                    <span>@l_ampoma</span>
                </a>
            </p>
        </header>
    </div>
  );
}

export default App;
